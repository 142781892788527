import React from "react"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <Seo lang={intl.locale} title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default injectIntl(NotFoundPage)
